import { createContext, useContext } from "react"
import { OptionI, TransactionI } from "types/types"

const WalletContext = createContext<{
  exchangeRate: number,
  cardOptions: OptionI[],
  cryptoOptions: OptionI[],
  balance: number,
  transactions: TransactionI[]
}>({
  exchangeRate: 0,
  cardOptions: [],
  cryptoOptions: [],
  balance: 0,
  transactions: []
})

const useWalletContext = () => useContext(WalletContext)

export {
  WalletContext,
  useWalletContext
}