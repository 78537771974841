import React, { FC, useState } from 'react'
import './Notification.scss'
import NotificationProps from './NotificationProps'
import coinImg from 'images/Coin.png'
import linkImg from 'images/Navigation/link_24.svg'
import okImg from 'images/Interface/checkOk.svg' 
import errImg from 'images/Interface/cancel_16.svg'
import { useSwipeable } from 'react-swipeable'
import { useText } from 'Context/TextContext'

const Notification: FC<NotificationProps> = ({type, coins, text, main, onCancel}) => {
  const appText = useText()
  const [swiped, setSwiped] = useState<boolean>(false)
  const getImg = () => {
    switch(type) {
      case 'Coin': return coinImg
      case 'Link': return linkImg
      case 'Success': return okImg
      case 'Error': return errImg
    }
  }

  const swipeEvent = useSwipeable({
    onSwipedDown: () => setSwiped(true),
    delta: 2
  }) 
  return (
    <div className={`notification ${main && 'notification_main'} ${swiped && 'notification_hide'}`} {...swipeEvent}>
      <img src={getImg()} alt="" className="notification__icon" />
      {
        type === 'Link' &&
          <div className="notification__texts">
            <span className="notification__title">
              {type === 'Link' && appText.link_copied}
            </span>
            <span className="notification__caption">
              {type === 'Link' && appText.link_copied_action}
            </span>
          </div>
      }
      {
        type === 'Success' && <span className="notification__done-text">{appText.perks_gift_done_button}!</span>
      }
      {
        type ==='Error' && <span className="notification__error-text">{text}</span>
      }
      {
        type === 'Coin' && onCancel && <button className='notification__button' onClick={onCancel}>Undo</button>
      }
    </div>
  )
}

export default Notification