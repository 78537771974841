import { LevelI, OptionI, ProfileI } from "types/types"

const initialLevel: LevelI = {
  id: 0,
  title: '',
  icon_utf: '',
  number: 0,
  start_range: 0,
  end_range: 0,
  external_id: ''
}

const emptyProfile: ProfileI = {
  avatar: '',
  regDate: '',
  daysRow: 0,
  lvl: 0,
  perks: 0,
  invites: 0,
  referralCode: '',
  balance: 0,
}

const cardOptions: OptionI[] = [
  {
    value: 'Mercuryo',
    label: 'Mercuryo',
  },
  {
    value: 'PayPal',
    label: 'PayPal',
  },
]

const succsessWithdrawPathRegex = /^\/wallet\/withdraw\/success\/\d+$/
const walletPathsRegex = /^\/wallet\/[a-z,/,\d]+/

export { initialLevel, emptyProfile, cardOptions, succsessWithdrawPathRegex, walletPathsRegex }