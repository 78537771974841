export interface textI {
  boost_description: string,
  change_name: string,
  clicking_since: string,
  days: string,
  invite_copy_button: string,
  invited: string,
  level: string,
  main: string,
  main_settings: string,
  onboarding_button: string,
  onboarding_point1: string,
  onboarding_point1_description: string,
  onboarding_point2: string,
  onboarding_point2_description: string,
  onboarding_point3: string,
  onboarding_point3_description: string,
  onboarding_welocome: string,
  onboarding_welocome_description: string,
  perk_click_description: string,
  perk_click_description_buy: string,
  perk_passive_description: string,
  perk_passive_description_buy: string,
  perks: string,
  perks_balance: string,
  perks_gift_description: string,
  perks_gift_done_button: string,
  perks_gift_subscribe_button: string,
  perks_gift_title: string,
  perks_menu1: string,
  perks_menu2: string,
  privacy_text: string,
  private: string,
  profile: string,
  purchased_perks: string,
  rating: string,
  rating_top_players: string,
  settings: string,
  wallet_exchange: string,
  wallet_history_not_found: string,
  wallet_history_title: string,
  wallet_profile_balance: string,
  wallet_withdraw: string,
  withdraw_amount: string,
  withdraw_amount_min: string,
  withdraw_balance: string,
  withdraw_button: string,
  withdraw_crypto: string,
  withdraw_crypto_address: string,
  withdraw_crypto_address_ph: string,
  withdraw_crypto_coin: string,
  withdraw_crypto_description: string,
  withdraw_fiat: string,
  withdraw_fiat_address: string,
  withdraw_fiat_address_ph: string,
  withdraw_fiat_description: string,
  withdraw_fiat_tm: string,
  withdraw_receive: string,
  withdraw_title: string,
  buy_button: string,
  error_gift: string,
  error_not_coin: string,
  gifts: string,
  link_copied: string,
  link_copied_action: string,
  new_level: string,
  new_level_button: string,
  withdraw: string,
  withdraw_crypto_coins: string,
  withdraw_crypto_max: string,
  withdraw_error_address: string,
  withdraw_error_amount: string,
  withdraw_error_id: string,
  withdraw_success: string,
  withdraw_success_button: string,
  withdraw_success_description: string,
  invite_title_profile: string,
  invite_text_profile: string,
  invite_title: string,
  invite_text: string,
  invite_send_button: string,
  invite_refer_via: string,
  invite_link: string
}

export const emptyText = {
  boost_description: '',
  change_name: '',
  clicking_since: '',
  days: '',
  invite_copy_button: '',
  invited: '',
  level: '',
  main: '',
  main_settings: '',
  onboarding_button: '',
  onboarding_point1: '',
  onboarding_point1_description: '',
  onboarding_point2: '',
  onboarding_point2_description: '',
  onboarding_point3: '',
  onboarding_point3_description: '',
  onboarding_welocome: '',
  onboarding_welocome_description: '',
  perk_click_description: '',
  perk_click_description_buy: '',
  perk_passive_description: '',
  perk_passive_description_buy: '',
  perks: '',
  perks_balance: '',
  perks_gift_description: '',
  perks_gift_done_button: '',
  perks_gift_subscribe_button: '',
  perks_gift_title: '',
  perks_menu1: '',
  perks_menu2: '',
  privacy_text: '',
  private: '',
  profile: '',
  purchased_perks: '',
  rating: '',
  rating_top_players: '',
  settings: '',
  wallet_exchange: '',
  wallet_history_not_found: '',
  wallet_history_title: '',
  wallet_profile_balance: '',
  wallet_withdraw: '',
  withdraw_amount: '',
  withdraw_amount_min: '',
  withdraw_balance: '',
  withdraw_button: '',
  withdraw_crypto: '',
  withdraw_crypto_address: '',
  withdraw_crypto_address_ph: '',
  withdraw_crypto_coin: '',
  withdraw_crypto_description: '',
  withdraw_fiat: '',
  withdraw_fiat_address: '',
  withdraw_fiat_address_ph: '',
  withdraw_fiat_description: '',
  withdraw_fiat_tm: '',
  withdraw_receive: '',
  withdraw_title: '',
  buy_button: '',
  error_gift: '',
  error_not_coin: '',
  gifts: '',
  link_copied: '',
  link_copied_action: '',
  new_level: '',
  new_level_button: '',
  withdraw: '',
  withdraw_crypto_coins: '',
  withdraw_crypto_max: '',
  withdraw_error_address: '',
  withdraw_error_amount: '',
  withdraw_error_id: '',
  withdraw_success: '',
  withdraw_success_button: '',
  withdraw_success_description: '',
  invite_title_profile: '',
  invite_text_profile: '',
  invite_title: '',
  invite_text: '',
  invite_send_button: '',
  invite_refer_via: '',
  invite_link: "",
  
}

export const enText: textI = {
  boost_description: "Multiply by {{multiplier}} for {{time}} seconds",
  buy_button: "Get it!",
  change_name: "Change real name",
  clicking_since: "Clicking since – {{date}}",
  days: "Days in row",
  error_gift: "Activity not confirmed",
  error_not_coin: "Not enough coins",
  gifts: "Gifts",
  invite_copy_button: "Copy link",
  invited: "Friends invited",
  level: "Lvl",
  link_copied: "Link copied",
  link_copied_action: "Send it to your friend",
  main: "Main",
  main_settings: "Main settings",
  new_level: "Congratulations, you have reached a new level",
  new_level_button: "Claim reward",
  onboarding_button: "Start Playing",
  onboarding_point1: "Tap to Farm Coins",
  onboarding_point1_description: "Tap the screen to collect as many coins as you can! The more you tap, the more",
  onboarding_point2: "Invite Friends",
  onboarding_point2_description: "Tap with friends, earn rewards! Share, compete, conquer together!",
  onboarding_point3: "Purchase Perks&Boosts",
  onboarding_point3_description: "Purchase Perks&Boosts",
  onboarding_welocome: "Welcome to Untitled Coin",
  onboarding_welocome_description: "Tap, collect coins, and conquer. Receive gifts for activity. Let's dive in and unleash the excitement! 💪💰✨",
  perk_click_description: "Up your coins per click",
  perk_click_description_buy: "Multiplies the number of coins per TAP. Get extra {{performance}} coins per TAP.",
  perk_passive_description: "Up your coins on active screen",
  perk_passive_description_buy: "Activate the Passive perk for a surge in coin collection every time you're on the app. Watch your riches grow with each second",
  perks: "Perks",
  perks_balance: "Your Balance",
  perks_gift_description: "Take part in the promotion and get free perks",
  perks_gift_done_button: "Done",
  perks_gift_subscribe_button: "Subscribe",
  perks_gift_title: "Gift for participation",
  perks_menu1: "Perks",
  perks_menu2: "Boosts",
  privacy_text: "Privacy settings in the ranking list",
  private: "Display in rating",
  profile: "Profile",
  purchased_perks: "Perks Purchased",
  rating: "Rating",
  rating_top_players: "Top Players",
  settings: "Settings",
  wallet_exchange: "Exchange",
  wallet_history_not_found: "You don't have a transaction history",
  wallet_history_title: "History",
  wallet_profile_balance: "Coin balance",
  wallet_withdraw: "Withdraw",
  withdraw: "Withdraw",
  withdraw_amount: "You are sending",
  withdraw_amount_min: "Min",
  withdraw_balance: "Balance",
  withdraw_button: "Send",
  withdraw_crypto: "To a crypto wallet",
  withdraw_crypto_address: "Address",
  withdraw_crypto_address_ph: "Enter wallet address",
  withdraw_crypto_coin: "Coin",
  withdraw_crypto_coins: "Coins",
  withdraw_crypto_description: "No commission",
  withdraw_crypto_max: "Max",
  withdraw_error_address: "Not valid address",
  withdraw_error_amount: "Minimum {{amount}} coins",
  withdraw_error_id: "Not valid id",
  withdraw_fiat: "To a bank account",
  withdraw_fiat_address: "ADDRESS / ID NUMBER",
  withdraw_fiat_address_ph: "Enter address or ID number",
  withdraw_fiat_description: "1% – 2%",
  withdraw_fiat_tm: "TRANSFER METHOD",
  withdraw_receive: "You receive",
  withdraw_success: "Congratulations",
  withdraw_success_button: "Back to Profile",
  withdraw_success_description: "You have successfully send {{amount}} USDT",
  withdraw_title: "How do you want to withdraw coins?",
  invite_title_profile: "Invite your friends",
  invite_text_profile: "More gifts for invited friends",
  invite_title: "Get a Mystery Prize worth up to §{{amount}} {{currency}}§ in crypto",
  invite_text: "Invite a friend, and both of you will receive a Mystery Prize",
  invite_send_button: "Invite",
  invite_refer_via: "Refer Via",
  invite_link: "Link",
}