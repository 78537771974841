import React, { FC } from 'react'
import './Balance.scss'
import Button from 'ui/Button/Button'
import withdrawImg from 'images/Arrow/arrow_up_circle_fill_32.svg'
import exchangeImg from 'images/Arrow/exchange_circle_fill_32.svg'
import List from 'ui/List/List'
import ListItem from 'ui/List/ListItem/ListItem'
import { displayDightsWithCommas } from 'utilits/displayDightsWithCommas'
import { useNavigate } from 'react-router-dom'
import { useWalletContext } from '../WalletContext'
import { getDateString } from 'utilits/getDateString'
import { useText } from 'Context/TextContext'

const Balance: FC = () => {
  const navigate = useNavigate()
  const text = useText()
  const { exchangeRate, balance, transactions } = useWalletContext()

  const showOnlyDecmical = (number: number) => {
    return ((number % 1000) / 1000).toFixed(3).toString().slice(2)
  }
  return (
    <>
      <div className="balance">
        <span className="balance__caption">{text.wallet_profile_balance}</span>
        <div className="balance__box">
          <span className="balance__coins">
            {balance > 999 && Math.floor(balance / 1000) + ','}
            <span className='balance__coins_hint'>{showOnlyDecmical(balance)}</span>
          </span>
          <div className="balance__coin-icon"/>
        </div>
        <span className="balance__usd">&asymp;{(balance * exchangeRate).toFixed(3)} USD</span>
      </div>
      <div className="balance__actions">
        <Button
          type='Plain'
          className='balance__actions-btn'
          onClick={() => navigate('../withdraw/method')}
        >
          <img src={withdrawImg} alt="" />
          {text.wallet_withdraw}
        </Button>
        <Button
          type='Plain'
          className='balance__actions-btn'
          disabled
        >
          <img src={exchangeImg} alt="" />
          {text.wallet_exchange}
        </Button>
      </div>
      <div className="balance__transactions">
        <span className="balance__transactions-caption">{text.wallet_history_title}</span>
        {
          transactions.length > 0 ? 
            <List>
              {
                transactions.map(t => 
                  <ListItem
                    noButton
                    key={t.created_at}
                    icon_img={withdrawImg}
                    mainText={
                      <div className='balance__transaction-text'>
                        <span>Withdraw</span>
                        <span>{displayDightsWithCommas(t.amount)} Coins</span>
                      </div>
                    }
                    caption={
                      <div className='balance__transaction-text'>
                        <span>{getDateString(t.created_at)}</span>
                        <span>{t.amount * exchangeRate} {t.currency}</span>
                      </div>
                    }
                  />
                )
              }
            </List> :
            <div className="balance__transactions-placeholder">
              <span className="balance__transactions-placeholder-icon">⏱️</span>
              <span className="balance__transactions-placeholder-text">{text.wallet_history_not_found}</span>
            </div>
        }
      </div>
      <div className="balance__bottom-pd" />
    </>
  )
}

export default Balance