import React, { useEffect, useRef, useState } from 'react'
import './App.scss'
import { Route, Routes } from 'react-router-dom'
import Main from 'components/Main/Main'
import Menu from 'components/Menu/Menu'
import WebApp from '@twa-dev/sdk'
import Perks from 'components/Perks/Perks'
import Gifts from 'components/Gifts/Gifts'
import Rating from 'components/Rating/Rating'
import Profile from 'components/Profile/Profile'
import Settings from 'components/Settings/Settings'
import Onboarding from 'components/Onboarding/Onboarding'
import { api } from 'utilits/api'
import Notification from 'ui/Notification/Notification'
import DeviceDetector from 'device-detector-js'
import Wallet from 'components/Wallet/Wallet'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useLockBodyScroll } from '@uidotdev/usehooks'
import { TextContext } from 'Context/TextContext'
import { enText, textI } from 'Context/textType'
import Share from 'components/Share/Share'

function App() {
  const device = new DeviceDetector()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const appRef = useRef<any>()
  useLockBodyScroll()

  const [text, setText] = useState<textI>(enText)
  
  const [error, setError] = useState<string>('')

  const showError = (text: string) => {
    WebApp.HapticFeedback.notificationOccurred('error')
    setError(text)
    setTimeout(() => setError(''), 3000);
  }
  WebApp.expand()
  WebApp.enableClosingConfirmation()
  WebApp.setHeaderColor('#000')
  WebApp.setBackgroundColor('#212121')

  const updateLastseen = () => {
    api.updateLastseen(WebApp.initDataUnsafe.user?.id || 0)
      .catch(() => showError('Connection lost'))
  } 
  const getText = () => {
    api.getText()
      .then(setText)
      .catch((err) => showError(err.error))
  }

  useEffect(() => {
    updateLastseen()
    getText()
    setInterval(updateLastseen, 5000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isKeyboardOpen ? disableBodyScroll(appRef.current) : enableBodyScroll(appRef.current)
  }, [isKeyboardOpen])
  return (
    <TextContext.Provider value={text}>
      <Routes>
        <Route path='/onboarding' element={<Onboarding />} /> 
      </Routes>
      <div className={`app ${device.parse(navigator.userAgent).device?.brand === 'Apple' && 'app_ios'} ${isKeyboardOpen && 'app_on-keyboard'}`} ref={appRef}>
        <div className='content'>
          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/perks' element={<Perks />}/>
            <Route path='/gifts' element={<Gifts />} />
            <Route path='/rating' element={<Rating />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/share' element={<Share />}/>
            <Route path='/settings' element={<Settings />} />
            <Route path='/wallet/*' element={<Wallet />}/>
          </Routes>
          { error !== '' && <Notification type="Error" text={error} main />}
        </div>
        <Menu />
      </div>
    </TextContext.Provider>
  )
}

export default App
