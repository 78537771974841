import React, { FC, useEffect } from 'react'
import './SuccessWithdraw.scss'
import { useNavigate, useParams } from 'react-router-dom'
import gif from 'images/PartyPopper.webp'
import Button from 'ui/Button/Button'
import WebApp from '@twa-dev/sdk'
import { useText } from 'Context/TextContext'
import { handleVarText } from 'utilits/handleVarText'

const SuccessWithdraw: FC = () => {
  const text = useText()
  const { usdt } = useParams()
  const navigate = useNavigate()

  WebApp.BackButton.onClick(() => {
    navigate('/wallet/balance')
    WebApp.BackButton.hide()
  })
  
  useEffect(() => {
    WebApp.BackButton.show()
  }, [])

  return (
    <div className='success-withdraw'>
      <img src={gif} alt="" className="success-withdraw__img" />
      <div className="success-withdraw__text">
        <span className="success-withdraw__title">{text.withdraw_success}</span>
        <span className="success-withdraw__caption">{handleVarText(text.withdraw_success_description, usdt || 0)}</span>
      </div>
      <Button
        shape='Large'
        onClick={() => navigate('/wallet/balance')}
      >
        {text.withdraw_success_button}
      </Button>
    </div>
  )
}

export default SuccessWithdraw