import { OptionI } from "types/types"

const processCardOptions = (rawCards: {
  id: number,
  image: string,
  title: string
}[]) => {
  const readyOptions: OptionI[] = rawCards.map(c => {
    return {
      value: c.id,
      label: 
        <div className="option">
          <img src={c.image} alt="" className="option__img" />
          <span className='option__title'>{c.title}</span>
        </div>
    }
  })

  return readyOptions
}

const processCryptoOptions = (rawCrypto: {
  id: number,
  image: string,
  title: string,
  min: string,
  network: string
}[]) => {
  const readyOptions: OptionI[] = rawCrypto.map(c => {
    return {
      value: {
        id: c.id,
        min: c.min
      },
      label: 
        <div className="option">
          <img src={c.image} alt="" className="option__img" />
          <span className='option__title'>{c.title}</span>
          <span className='option__caption'>{c.network}</span>
        </div>
    }
  })

  return readyOptions
}

export {
  processCardOptions,
  processCryptoOptions
}