import React, { FC, useEffect, useState } from 'react'
import './Wallet.scss'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { OptionI, ProfileI, TransactionI } from 'types/types'
import { emptyProfile, succsessWithdrawPathRegex } from 'consts/consts'
import { api } from 'utilits/api'
import Notification from 'ui/Notification/Notification'
import WebApp from '@twa-dev/sdk'
import { getDateString } from 'utilits/getDateString'
import Balance from './Balance/Balance'
import MethodPick from './MethodPick/MethodPick'
import Withdraw from './Withdraw/Withdraw'
import SuccessWithdraw from './SuccessWithdraw/SuccessWithdraw'
import { processCardOptions, processCryptoOptions } from './WalletServe'
import { WalletContext } from './WalletContext'
import { useLockBodyScroll } from '@uidotdev/usehooks'
import { useText } from 'Context/TextContext'
import { handleVarText } from 'utilits/handleVarText'

const Wallet: FC = () => {
  useLockBodyScroll()
  const navigate = useNavigate()
  const location = useLocation()
  const text = useText()
  const [error, setError] = useState<string>('')

  const [profile, setProfile] = useState<ProfileI>(emptyProfile)
  const [cardOptions, setCardOptions] = useState<OptionI[]>([])
  const [cryptoOptions, setCryptoOptions] = useState<OptionI[]>([])
  const [rate, setRate] = useState<number>(0)
  const [transactions, setTransactions] = useState<TransactionI[]>([])

  const showError = (text: string) => {
    WebApp.HapticFeedback.notificationOccurred('error')
    setError(text)
    setTimeout(() => setError(''), 3000);
  }

  const getProfile = () => {
    api.getProfile()
      .then((res: any) => {
        setProfile({
          ...profile,
          balance: res.coin,
          avatar: res.avatar,
          regDate: res.created_at,
          username: res.username
        })
      })
      .catch(err => showError(err.error))
  }

  const getWalletInfo = () => {
    api.getWalletInfo()
      .then((res: any) => {
        setRate(res.currency_rate)
        setCardOptions(processCardOptions(res.fiat_payment_systems))
        setCryptoOptions(processCryptoOptions(res.crypto_payment_systems))
        setTransactions(res.transactions)
      })
      .catch(err => showError(err.error))
  }

  useEffect(() => {
    WebApp.BackButton.hide()
    getProfile()
    getWalletInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    location.pathname === '/wallet/balance' && getWalletInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className='wallet'>
      <div className="wallet__nav">
        {
          location.pathname.match(succsessWithdrawPathRegex) ?
            <h2 className="wallet__title">{text.withdraw}</h2> :
            <div className="wallet__profile">
              <img
                src={profile.avatar} alt=""
                className="wallet__profile-avatar"
                onClick={() => navigate('/profile')}
              />
              <div className="wallet__profile-text">
                <span className="wallet__profile-name">@{profile.username}</span>
                <span className="wallet__profile-reg">{handleVarText(text.clicking_since, getDateString(profile.regDate))}</span>
              </div>
            </div>
        }
        <button
          className="wallet__settings-btn"
          onClick={() => navigate('/settings')}
        />
      </div>
      <WalletContext.Provider value={{
          exchangeRate: rate,
          cardOptions: cardOptions,
          cryptoOptions: cryptoOptions,
          balance: profile.balance,
          transactions: transactions
        }}
      >
        <Routes>
          <Route path='balance' element={<Balance/>}/>
          <Route path='withdraw/*'>
            <Route path='method' element={<MethodPick />}/>
            <Route path='crypto' element={<Withdraw type='Crypto' />}/>
            <Route path='card' element={<Withdraw type='Card' />}/>
            <Route path='success/:usdt' element={<SuccessWithdraw />}/>
          </Route>
        </Routes>
      </WalletContext.Provider>
      { error !== '' && <Notification type='Error' text={error} /> }
    </div>
  )
}

export default Wallet