import React, { FC, useEffect, useState } from 'react'
import './Profile.scss'
import { useNavigate } from 'react-router-dom'
import { ProfileI } from 'types/types'
import { emptyProfile } from 'consts/consts'
import { api } from 'utilits/api'
import Notification from 'ui/Notification/Notification'
import { getDateString } from 'utilits/getDateString'
import WebApp from '@twa-dev/sdk'
import { useLockBodyScroll } from '@uidotdev/usehooks'
import { useText } from 'Context/TextContext'
import List from 'ui/List/List'
import ListItem from 'ui/List/ListItem/ListItem'

const Profile: FC = () => {
  useLockBodyScroll()
  const text = useText()
  const navigate = useNavigate()
  const [profile, setProfile] = useState<ProfileI>(emptyProfile)
  const [error, setError] = useState<string>('')

  WebApp.BackButton.onClick(() => {
    navigate(-1)
    WebApp.BackButton.hide()
  })

  const showError = (text: string) => {
    WebApp.HapticFeedback.notificationOccurred('error')
    setError(text)
    setTimeout(() => setError(''), 3000);
  }

  const getProfile = () => {
    api.getProfile()
      .then((res: any) => {
        setProfile({
          ...profile,
          invites: res.count_referrals,
          daysRow: res.days_activity_in_row,
          lvl: res.level.number,
          perks: res.count_purchased_perks,
          balance: res.coin,
          referralCode: res.referral_code,
          avatar: res.avatar,
          regDate: res.created_at
        })
      })
      .catch(err => showError(err.error))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getProfile, [])
  
  useEffect(() => {
    WebApp.BackButton.show()
  }, [])
  
  return (
    <div className='profile'>
      <div className="profile__header">
        <h1 className="profile__title">{text.profile}</h1>
        <button
          className="profile__settings-btn"
          onClick={() => navigate('/settings')}
        />
      </div>
      <img src={profile.avatar} alt="" className="profile__avatar" />
      <div className="profile__info">
        <span className="profile__info-caption">{text.wallet_profile_balance}</span>
        <div className="profile__balance">
          <span className="profile__balance-coins">{profile.balance}</span>
          <div className="profile__balance-coin-icon"/>
        </div>
        <span className="profile__reg-info">{text.clicking_since}{getDateString(profile.regDate)}</span>
      </div>
      <div className="profile__stats">
        <div className="profile__stats-card">
          <span className="profile__stats-card-icon">&#128293;</span>
          <div className="profile__stats-card-texts">
            <span className="profile__stats-card-count">{profile.daysRow}</span>
            <span className="profile__stats-card-caption">{text.clicking_since}</span>
          </div>
        </div>
        <div className="profile__stats-card">
          <span className="profile__stats-card-icon">&#128184;</span>
          <div className="profile__stats-card-texts">
            <span className="profile__stats-card-count">{profile.perks}</span>
            <span className="profile__stats-card-caption">{text.purchased_perks}</span>
          </div>
        </div>
        <div className="profile__stats-card">
          <span className="profile__stats-card-icon">&#127941;</span>
          <div className="profile__stats-card-texts">
            <span className="profile__stats-card-count">{profile.lvl}</span>
            <span className="profile__stats-card-caption">{text.level}</span>
          </div>
        </div>
        <div className="profile__stats-card">
          <span className="profile__stats-card-icon">&#129312;</span>
          <div className="profile__stats-card-texts">
            <span className="profile__stats-card-count">{profile.invites}</span>
            <span className="profile__stats-card-caption">{text.invited}</span>
          </div>
        </div>
      </div>
      <div className="profile__divider"/>
      <List>
        <ListItem
          icon_utf='👥'
          mainText={text.invite_title_profile}
          caption={text.invite_text_profile}
          bigIcon
          buttonEvent={() => navigate('/share')}
        />
      </List>
      { error !== '' && <Notification type='Error' text={error} /> }
    </div>
  )
}

export default Profile