import React, { FC, ReactNode } from 'react'
import './List.scss'

const List: FC<{children?: ReactNode}> = ({children}) => {
  return (
    <div className="list">
      {children}
    </div>
  )
}

export default List