import React, { FC, useEffect, useState } from 'react'
import './Share.scss'
import { useNavigate } from 'react-router-dom'
import { useText } from 'Context/TextContext'
import gif from 'images/shareGif.gif'
import Button from 'ui/Button/Button'
import { emptyProfile } from 'consts/consts'
import { ProfileI } from 'types/types'
import { api } from 'utilits/api'
import Notification from 'ui/Notification/Notification'
import WebApp from '@twa-dev/sdk'

const Share: FC = () => {
  const text = useText()
  const navigate = useNavigate()
  const [profile, setProfile] = useState<ProfileI>(emptyProfile)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  WebApp.BackButton.onClick(() => {
    navigate(-1)
    WebApp.BackButton.hide()
  })
  const showError = (text: string) => {
    WebApp.HapticFeedback.notificationOccurred('error')
    setError(text)
    setTimeout(() => setError(''), 3000);
  }

  const shareLink = () => {
    window.open(profile.referralCode, '_blank')
  }
  const copyLink = () => {
    navigator.clipboard.writeText(profile.referralCode)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 5000)
  }

  const getProfile = () => {
    api.getProfile()
      .then((res: any) => {
        setProfile({
          ...profile,
          invites: res.count_referrals,
          daysRow: res.days_activity_in_row,
          lvl: res.level.number,
          perks: res.count_purchased_perks,
          balance: res.coin,
          referralCode: res.referral_code,
          avatar: res.avatar,
          regDate: res.created_at
        })
      })
      .catch(err => showError(err.error))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getProfile, [])
  return (
    <div className='share'>
      <div className="share__header">
        <h1 className="share__title">{text.profile}</h1>
        <button
          className="share__settings-btn"
          onClick={() => navigate('/settings')}
        />
      </div>
      <span className="share__text">
        {text.invite_title.split('§')[0]}<span className='share__text_highlight'>10 USDT</span>{text.invite_title.split('§')[2]}
        </span>
      <span className="share__caption">{text.invite_text}</span>
      <img src={gif} alt="" className="share__gif" />
      <span className="share__ref-title">{text.invite_refer_via}</span>
      <div className="share__invite-method">
        <span className="share__invite-method-name">{text.invite_link}</span>
        <Button
          type='Plain'
          onClick={copyLink}
          className='share__btn'
        >
          <span className='share__invite-link'>{profile.referralCode}</span> <div className="share__btns-icon share__btns-icon_link"/>
        </Button>
      </div>
      <div className="share__invite-method">
        <span className="share__invite-count">{profile.invites}</span>
        <span className="share__invite-count-text">{text.invited}</span>
        <Button
          onClick={shareLink}
          className='share__btn'
        >
          <div className="share__btns-icon share__btns-icon_invite"/>{text.invite_send_button}
        </Button>
      </div>
      { isCopied && <Notification type='Link' />}
      { error !== '' && <Notification type='Error' text={error} /> }
    </div>
  )
}

export default Share