import React, { FC, useEffect, useRef, useState } from 'react'
import './Select.scss'
import SelectProps from './SelectProps'
import { OptionI } from 'types/types'
import { useOutsideClick } from '@chakra-ui/react'

const Select: FC<SelectProps> = ({options, onChange, placeholder}) => {
  const [selectedItem, setSelectedItem] = useState<OptionI|undefined>(undefined)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const selectRef = useRef<any>()
  useOutsideClick({
    ref: selectRef,
    handler: () => setIsOpen(false)
  })

  const handleSelect = (option: OptionI) => {
    setSelectedItem(option)
    onChange && onChange(option.value, option)
  }

  useEffect(() => {
    setIsOpen(false)
  }, [selectedItem])

  return (
    <div
      className={`select ${isOpen && 'select_open'}`}
      ref={selectRef}
      onClick={() => setIsOpen(state => !state)}
    >
      <span
        className="select__input"
      >
        {selectedItem?.label || placeholder}
      </span>
      <div className="select__control" />
      <div className={`select__menu ${!isOpen && 'select__menu_hidden'}`}>
        {
          options && options.map((o, i) => 
            <span
              key={i}
              className='select__option'
              onClick={() => handleSelect(o)}
            >
              {o.label}
              { selectedItem?.label === o.label && <div className='select__option-checked'/> }
            </span>
          )
        }
      </div>
    </div>
  )
}

export default Select