import React, { FC } from 'react'
import './ListItem.scss'
import ListItemProps from './ListItemProps'

const ListItem: FC<ListItemProps> = ({icon_utf, icon_img, mainText, caption, buttonEvent, noButton, bigIcon}) => {
  return (
    <div className='list-item' onClick={buttonEvent}>
      <div className={`list-item__icon ${bigIcon && 'list-item__icon_big'}`}>
        {icon_utf}
        {icon_img && <img src={icon_img} alt='' width={36} height={36}/>}
      </div>
      <div className="list-item__text">
        <h4 className="list-item__title">{mainText}</h4>
        <span className="list-item__subtitle">{caption}</span>
      </div>
      {
        !noButton && <div className='list-item__button'/>
      }
    </div>
  )
}

export default ListItem