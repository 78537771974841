import React, { FC, useEffect, useRef, useState } from 'react'
import './Withdraw.scss'
import Select from 'ui/Select/Select'
import Button from 'ui/Button/Button'
import { displayDightsWithCommas } from 'utilits/displayDightsWithCommas'
import coinIcon from 'images/Coin.png'
import currencyIcon from 'images/ic_usdt.svg'
import { validate } from 'crypto-address-validator-ts'
import WebApp from '@twa-dev/sdk'
import { useNavigate } from 'react-router-dom'
import { useWalletContext } from '../WalletContext'
import { api } from 'utilits/api'
import Notification from 'ui/Notification/Notification'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useText } from 'Context/TextContext'
import { handleVarText } from 'utilits/handleVarText'

const Withdraw: FC<{type: 'Card'|'Crypto'}> = ({type}) => {
  const text = useText()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const navigate = useNavigate()
  const withdrawRef = useRef<any>()
  const { cardOptions, exchangeRate, balance } = useWalletContext()

  const [error, setError] = useState<string>('')

  WebApp.BackButton.onClick(() => {
    navigate(-1)
    WebApp.BackButton.hide()
  })

  const [amount, setAmount] = useState<string>('')
  const [cryptoAddress, setCryptoAddres] = useState<string>('')
  const [platform, setPlatform] = useState<number>(0)
  const [cardAddress, setCardAddress] = useState<string>('')

  const minAmount = 5

  const [amountError, setAmountError] = useState<string>('')
  const [cardAddressError, setCardAddressError] = useState<string>('')
  const [cryptoAddressError, setCryptoAddressError] = useState<string>('')

  const showError = (text: string) => {
    WebApp.HapticFeedback.notificationOccurred('error')
    setError(text)
    setTimeout(() => setError(''), 3000);
  }
  
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/,/g, "")
    setAmountError('')
    if(value.match(/^[0-9]*$/g) && Number(value) <= balance) {
      setAmount(
        value !== '' ?
          displayDightsWithCommas(value) :
          value
      )
    }
  }
  const setMaxAmount = () => {
    setAmountError('')
    setAmount(displayDightsWithCommas(balance))
  }
  const handleCryptoAddressChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCryptoAddressError('')
    setCryptoAddres(e.currentTarget.value)
  }
  const handleCardAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCardAddressError('')
    setCardAddress(e.currentTarget.value)
  }

  const sendViaCard = () => {
    const coins = Number(amount.replace(/,/g, ""))
    api.withdrawFiat(platform, cardAddress, coins)
      .then(() => {
        WebApp.HapticFeedback.notificationOccurred("success")
        navigate(`../success/${Number(amount.replace(/,/g, "")) / minAmount}`)
      })
      .catch((err) => showError(err.error))
  }
  const sendViaCrypto = () => {
    const coins = Number(amount.replace(/,/g, ""))
    api.withdrawCrypto(1, coins, cryptoAddress)
      .then(() => {
        WebApp.HapticFeedback.notificationOccurred("success")
        navigate(`../success/${Number(amount.replace(/,/g, "")) / minAmount}`)
      })
      .catch((err) => showError(err.error))
  }

  const handleSend = () => {
    const finalAmount = Number(amount.replace(/,/g, ""))
    if(finalAmount < minAmount) {
      setAmountError(handleVarText(text.withdraw_error_amount, minAmount))
    } else if(type === 'Crypto') {
      validate(cryptoAddress, 'usdt', { networkType: '' , chainType: ''}) ?
        sendViaCrypto() :
        setCryptoAddressError(text.withdraw_error_address)
    } else {
      cardAddress.length > 6 && cardAddress.length < 20 ?
        sendViaCard() :
        setCardAddressError(text.withdraw_error_id)
    }
  }
  
  useEffect(() => {
    WebApp.BackButton.show()
  }, [])

  useEffect(() => {
    isKeyboardOpen ? disableBodyScroll(withdrawRef.current) : enableBodyScroll(withdrawRef.current)
  }, [isKeyboardOpen])
  return (
    <div className='withdraw' ref={withdrawRef}>
      {
        type === 'Card' ?
          <>
            <span className="withdraw__input-caption">{text.withdraw_fiat_tm}</span>
            <Select
              options={cardOptions}
              placeholder='Select platform'
              onChange={v => setPlatform(Number(v))}
            />
            {
              platform &&
                <>
                  <span className="withdraw__input-caption">{text.withdraw_fiat_address}</span>
                  <input
                    type="text"
                    className={`withdraw__card-address-input ${cardAddressError !== '' && 'withdraw__card-address-input_error'}`}
                    value={cardAddress}
                    onChange={handleCardAddressChange}
                    placeholder={text.withdraw_fiat_address_ph}
                  />
                  { cardAddressError && <span className="withdraw__error">{cardAddressError}</span> }
                </>
            }
          </> :
          <>
            <span className="withdraw__input-caption">{text.withdraw_crypto_coin}</span>
            <div className="withdraw__coin-box">
              <img src={currencyIcon} alt="" className="withdraw__coin-icon" />
              <span className="withdraw__coin-name">usdt</span>
              <span className="withdraw__coin-net">Trc20</span>
            </div>
            <span className="withdraw__input-caption">{text.withdraw_crypto_address}</span>
            <textarea
              className={`withdraw__address-input ${cryptoAddressError !== '' && 'withdraw__address-input_error'}`}
              placeholder={text.withdraw_crypto_address_ph}
              value={cryptoAddress}
              onChange={handleCryptoAddressChange}
            />
            { cryptoAddressError && <span className="withdraw__error">{cryptoAddressError}</span> }
          </>
      }
      <span className="withdraw__input-caption">{text.withdraw_amount}</span>
      <div className={`withdraw__input-box ${amountError && 'withdraw__input-box_error'}`}>
        <input
          className={`withdraw__input ${amountError && 'withdraw__input_error'}`}
          placeholder={`${text.withdraw_amount_min} ${minAmount}`}
          inputMode="numeric"
          value={amount}
          onChange={handleAmountChange}
        />
        <span className="withdraw__input-suffix">{text.withdraw_crypto_coins}</span>
        <Button
          type='Plain'
          className='withdraw__input-max-btn'
          onClick={setMaxAmount}
        >
          {text.withdraw_crypto_max}
        </Button>
      </div>
      { amountError && <span className="withdraw__error">{amountError}</span> }
      <div className="withdraw__balance-box">
        <span className="withdraw__balance-caption">{text.withdraw_balance}:</span>
        <span className="withdraw__balance">{(displayDightsWithCommas(balance))}</span>
        <img src={coinIcon} alt="" className="withdraw__balance-coin-icon" />
      </div>
      <div className="withdraw__receive-box">
        {
          Number(amount) > 0 &&
           <>
            <div className="withdraw__receive-caption">
              <img src={currencyIcon} alt="" className="withdraw__receive-caption-icon" />
              <div className="withdraw__receive-caption-text">{text.withdraw_receive}</div>
            </div>
            <div className="withdraw__receive-amount-box">
              <span className="withdraw__receive-amount">
                {(Number(amount.replace(/,/g, "")) * exchangeRate).toFixed(7)}
              </span>
              <span className="withdraw__receive-currency">USDT</span>
            </div>
           </>
        }
      </div>
      <Button
        shape='Large'
        className='withdraw__send-btn'
        disabled={
          amount === '' ||
          (
            type === 'Card' ?
              platform === 0 || cardAddress.trim() === '' :
              cryptoAddress === ''
          )
        }
        onClick={handleSend}
      >
        {text.withdraw_button}
      </Button>
      { error !== '' && <Notification type='Error' text={error} /> }
    </div>
  )
}

export default Withdraw