import React, { FC, useEffect } from 'react'
import './MethodPick.scss'
import List from 'ui/List/List'
import ListItem from 'ui/List/ListItem/ListItem'
import walletImg from 'images/Interface/wallet-03.svg'
import cardImg from 'images/Interface/card_circle_fill_32.svg'
import { useNavigate } from 'react-router-dom'
import WebApp from '@twa-dev/sdk'
import { useText } from 'Context/TextContext'

const MethodPick: FC = () => {
  const navigate = useNavigate()
  const text = useText()

  WebApp.BackButton.onClick(() => {
    navigate(-1)
    WebApp.BackButton.hide()
  })
  useEffect(() => {
    WebApp.BackButton.show()
  }, [])
  
  return (
    <div className='method'>
      <h2 className="method__headline">{text.withdraw_title}</h2>
      <List>
        <ListItem
          icon_img={walletImg}
          mainText={text.withdraw_crypto}
          caption={text.withdraw_crypto_description}
          buttonEvent={() => navigate('../crypto')}
        />
      </List>
      <List>
        <ListItem
          icon_img={cardImg}
          mainText={text.withdraw_fiat}
          caption={text.withdraw_fiat_description}
          buttonEvent={() => navigate('../card')}
        />
      </List>
    </div>
  )
}

export default MethodPick