import React, { FC } from 'react'
import './Menu.scss'
import { Link, useLocation } from 'react-router-dom'
import { walletPathsRegex } from 'consts/consts'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import { useText } from 'Context/TextContext'

const Menu: FC = () => {
  const text = useText()
  const location = useLocation()
  const isKeyboardOpen = useDetectKeyboardOpen()
  
  return (
    <div className={`menu ${isKeyboardOpen ? 'menu_hidden' : ''}`}>
      <Link 
        to='/'
        className={`menu__button ${location.pathname === '/' && 'menu__button_current'}`}
      >
        <div className="menu__button-icon menu__button-icon_main"/>
        <span className="menu__button-name">{text.main}</span>
      </Link>
      <Link
        to='/perks'
        className={`menu__button ${(location.pathname === '/perks' || location.pathname === '/gifts') && 'menu__button_current'}`}
      >
        <div className="menu__button-icon menu__button-icon_perks"/>
        <span className="menu__button-name">{text.perks}</span>
      </Link>
      <Link
        to='/rating'
        className={`menu__button ${location.pathname === '/rating' && 'menu__button_current'}`}
      >
        <div className="menu__button-icon menu__button-icon_rating"/>
        <span className="menu__button-name">{text.rating}</span>
      </Link>
      <Link
        to='/wallet/balance'
        className={`menu__button ${(location.pathname === '/profile' || location.pathname === '/settings' || location.pathname.match(walletPathsRegex)) && 'menu__button_current'}`}
      >
        <div className="menu__button-icon menu__button-icon_profile"/>
        <span className="menu__button-name">{text.profile}</span>
      </Link>
    </div>
  )
}

export default Menu