import React, { FC, useState } from 'react'
import './ItemPopup.scss'
import ItemPopupProps from './ItemPopupProps'
import { useLockBodyScroll } from '@uidotdev/usehooks'
import Button from 'ui/Button/Button'
import coinImg from 'images/Coin.png'
import { useSwipeable } from 'react-swipeable'
import { useText } from 'Context/TextContext'

const ItemPopup: FC<ItemPopupProps> = ({onClose, onSubmit, item, balance}) => {
  useLockBodyScroll()
  const text = useText()
  const [swiped, setSwiped] = useState<boolean>(false)

  const closePopup = () => {
    setSwiped(true)
    setTimeout(onClose, 300)
  }

  const swipeEvent = useSwipeable({
    onSwipedDown: closePopup,
    delta: 30
  }) 
  return (
    <div className='item-popup' onClick={closePopup}>
      <div className={`item-popup__box ${swiped && 'item-popup__box_hide'}`} {...swipeEvent}>
        <button className="item-popup__close-btn" onClick={closePopup} />
        <div className="item-popup__icon">
          {item.icon_utf}
          {item.lvl && <span className="item-popup__lvl">{item.lvl} lvl</span>}
        </div>
        <h3 className="item-popup__name">{item.name}</h3>
        <span className="item-popup__description">{item.text}</span>
        <span className="item-popup__price">
          {item.price}
          <img src={coinImg} alt='' className="item-popup__price-icon"/>
        </span>
        <Button shape='Large' onClick={onSubmit} disabled={balance < item.price}>{text.buy_button}</Button>
      </div>
    </div>
  )
}

export default ItemPopup