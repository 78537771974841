import WebApp from "@twa-dev/sdk"

const urlParams = new URLSearchParams(window.location.search)
const token = urlParams.get('token')

class Api {
  private url: string
  private userId: number
  private headers: any

  constructor(url: string, id: number) {
    this.url = url
    this.userId = id
    this.headers = {
      'Content-Type': 'application/json',
    }
  }

  private getResponse(res: Response) {
    if (res.ok) {
      return res.json()
    }

    return res.text().then(text => {throw JSON.parse(text)})
  }

  private checkError(res: Response) {
    if(!res.ok) {
      return res.text().then(text => {throw JSON.parse(text)})
    }
    return
  }

  updateOnboarding() {
    return fetch(`${this.url}/api/user/onboarding/update?tgUserId=${this.userId}&token=${token}`)
      .catch(this.checkError)
  }
  
  getInitialData() {
    return fetch(`${this.url}/api/user/clicker?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  getBalance() {
    return fetch(`${this.url}/api/user/balance?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  updateLastseen(userId: number) {
    return fetch(`${this.url}/api/user/lastseen/update?tgUserId=${userId}&token=${token}`)
      .then(this.checkError)
  }

  getProfile() {
    return fetch(`${this.url}/api/user/profile?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  getMultiplier() {
    return fetch(`${this.url}/api/perk/multiplier?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  updateBalance(coins: number, clickTime: number) {
    return fetch(`${this.url}/api/user/balance/update`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        token: token,
        tgUserId: this.userId,
        coins: coins,
        time: clickTime
      })
    })
      .then(this.getResponse)
  }

  getRating() {
    return fetch(`${this.url}/api/user/ratings?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  getPerks() {
    return fetch(`${this.url}/api/perk/all?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  getUserPerks() {
    return fetch(`${this.url}/api/perk/purchased?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  buyPerk(perkId: number) {
    return fetch(`${this.url}/api/perk/buy?tgUserId=${this.userId}&token=${token}&perkId=${perkId}`)
      .then(this.checkError)
  }

  getBoosts() {
    return fetch(`${this.url}/api/boost/all`)
      .then(this.getResponse)
  }

  buyBoost(boostId: number) {
    return fetch(`${this.url}/api/boost/buy?tgUserId=${this.userId}&token=${token}&boostId=${boostId}`)
      .then(this.checkError)
  }

  getUserGifts() {
    return fetch(`${this.url}/api/gift/all?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  buyGift(giftId: number) {
    return fetch(`${this.url}/api/gift/buy?tgUserId=${this.userId}&token=${token}&giftId=${giftId}`)
      .then(this.checkError)
  }

  getReferrals() {
    return fetch(`${this.url}/api/user/referrals?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  getReferralCode() {
    return fetch(`${this.url}/api/user/referral-code?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  getSettings() {
    return fetch(`${this.url}/api/user/settings?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  updateFakeName() {
    return fetch(`${this.url}/api/user/settings/fakename/update?tgUserId=${this.userId}&token=${token}`)
      .then(this.checkError)
  }

  updatePrivacy() {
    return fetch(`${this.url}/api/user/settings/private/update?tgUserId=${this.userId}&token=${token}`)
      .then(this.checkError)
  }

  getWalletInfo() {
    return fetch(`${this.url}/api/wallet/info?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }

  withdrawFiat(paymentSystemId: number, address: string, amount: number) {
    return fetch(`${this.url}/api/wallet/withdraw/fiat`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        token: token,
        tgUserId: this.userId,
        paymentSystemId: paymentSystemId,
        amount: amount,
        address: address
      })
    })
      .then(this.checkError)
  }

  withdrawCrypto(paymentSystemId: number, amount: number, address: string) {
    return fetch(`${this.url}/api/wallet/withdraw/crypto`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        token: token,
        tgUserId: this.userId,
        paymentSystemId: paymentSystemId,
        address: address,
        amount: amount,
      })
    })
      .then(this.checkError)
  }

  getText() {
    return fetch(`${this.url}/api/translations?tgUserId=${this.userId}&token=${token}`)
      .then(this.getResponse)
  }
}

const api = new Api(window.env.APP_API_ENDPOINT, WebApp.initDataUnsafe.user?.id || 0)

export { api }